import { EspaceData } from '@innedit/innedit';
import {
  AbonnementType,
  DocumentType,
  EspaceType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

import Button from '../../components/Button';

interface AbonnementProps {
  data: DocumentType<AbonnementType>;
}

const Abonnement: FC<AbonnementProps> = ({ data }) => {
  const [boutique, setBoutique] = useState<DocumentType<EspaceType>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    let isMounted = true;

    if (data) {
      const { boutique: espaceId } = data;
      const espaceData = new EspaceData();

      espaceData
        .findById(espaceId)
        .then(document => {
          if (isMounted) {
            setBoutique(document);
          }

          return undefined;
        })
        .catch(e => setError(e.message));
    }

    return () => {
      isMounted = false;
    };
  }, [JSON.stringify(data)]);

  if (error) {
    return <div>{error}</div>;
  }

  const { createdAt } = data;

  return (
    <li className="flex space-x-3">
      <span>{dayjs(createdAt).format('dddd D MMM YYYY')}</span>
      {boutique && (
        <Button to={`/${boutique.id}/`} variant="link">
          {boutique.name}
        </Button>
      )}
    </li>
  );
};

export default Abonnement;
