import { FeatureData } from '@innedit/innedit';
import { AbonnementType, DocumentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Abonnement from '../../containers/Abonnement';
import TemplateUser from '../../templates/user';
import requireUser, { UserProps } from '../../utils/requireUser';

const PageUserCalendrier: FC<PageProps & UserProps> = ({
  params: { espaceId },
  user,
}) => {
  const [abonnements, setAbonnements] =
    useState<DocumentType<AbonnementType>[]>();

  useEffect(() => {
    let isMounted = true;
    const featureData = new FeatureData<AbonnementType>({
      espaceId,
      collectionName: 'abonnements',
    });

    if (user.subscriptions && user.subscriptions.length > 0) {
      featureData
        .find({
          wheres: {
            user: user.id,
          },
        })
        .then(docs => {
          if (isMounted) {
            setAbonnements(docs);
          }

          return true;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [JSON.stringify(user.subscriptions)]);

  return (
    <TemplateUser title="Mes abonnements">
      {abonnements && abonnements.length > 0 && (
        <ul>
          {abonnements.map(abonnement => (
            <Abonnement key={abonnement.id} data={abonnement} />
          ))}
        </ul>
      )}
      {abonnements && 0 === abonnements.length && <div>Aucun abonnement</div>}
    </TemplateUser>
  );
};

export default requireUser(PageUserCalendrier);
